// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-calendar-js": () => import("../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legacy-js": () => import("../src/pages/Legacy.js" /* webpackChunkName: "component---src-pages-legacy-js" */),
  "component---src-pages-page-2-js": () => import("../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-players-js": () => import("../src/pages/players.js" /* webpackChunkName: "component---src-pages-players-js" */),
  "component---src-pages-sponsors-js": () => import("../src/pages/sponsors.js" /* webpackChunkName: "component---src-pages-sponsors-js" */)
}

